
/*----------------------
 FOOTER
-----------------------*/
#footer {
    padding: 80px 0px;
    position: relative;

    .footer-brand {
        font-size: 30px;
    }
    .footer-links {
        padding-left: 0;
        list-style-type: none
    }

}

#footer {
    background: url("../images/bg/10.jpg");
    -webkit-background-size: cover;
    background-size: cover;
}

.footer-overlay {
    background: #111;
    opacity: .94;
}




.footer-widget {
    p {
        color: $black-lighter;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 32px;
        font-size: 14px;
    }
     h3 {
        color: $light;
        margin-bottom: 30px;
    }
}



.footer-links {
    a {
        color: $black-lighter;
        line-height: 38px;
        font-size: 14px;
        text-transform: capitalize;
    }
    i{
        width: 25px;
    }
}


.footer-copy {
    color:$black-lighter;
    font-size: 16px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 12px;
        width: 58%;
        height: 1px;
        background: #94959a;
    }
}

.current-year {
    color: $primary-color !important;
}




/*-----------------------------
 SCROLL TO TOP
--------------------------------*/

.scroll-to-top{
    position: fixed;
    content: "";
    right: 30px;
    bottom: 50px;
    z-index: 999;
    background: $primary-color;
    color: $light!important;
    border-radius: 100%;
    opacity: 0;
    
    i{
        width: 60px;
        height: 60px;
        border-radius: 100%;
        text-align: center;
        font-size: 22px;
        line-height: 60px;
        display: block;
         -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
        box-shadow:0 7px 22px rgba(0, 0, 0, 0.08) ;
    }
    
}


.scroll-to-top:hover, .scroll-to-top:focus{
    text-decoration: none;
}

.reveal{
    transition: all .3s;
    cursor: pointer;
    opacity: 1;
}
