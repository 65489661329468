
/*----------------------------------
    MEDIUM LAYOUT: 1280px
-----------------------------------*/


@include large-desktop{
    .dropdown-item {
        padding-top: .75rem;
        padding-bottom: .75rem
    }
    .dropdown-item + .dropdown-item {
        border-top: 1px solid rgba(0, 0, 0, .1)
    }
     .navbar .dropdown-menu {
        transform: translateY(.5rem);
        opacity: 0
    }

    .navbar .dropdown-menu.show {
        animation: .2s forwards d;
    }

    @keyframes d {
        to {
            transform: translateY(0);
            opacity: 1
        }
    }
    .navbar .dropdown-menu {
        padding-top: 0;
        padding-bottom: 0;
        border: 0
    }
     .navbar .nav-item-divider {
        display: block
    }
}


/*----------------------------------
    TABLET LAYOUT: 768px
------------------------------------*/


@include desktop{
    .section-title {
      font-size: 30px; }
    .background-text {
      font-size: 60px; }
    .main-banner h1.display-4 {
        line-height: 62px;
        font-size: 30px;
    }
    .main-nav .navbar-brand h4{
        color: $light;
    }
    
    .top-info-block{
        text-align: center;
        margin: 10px 0px;
        clear: both;
    }
    
    .icon-block{
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .main-navigation{
        margin-top: 20px;
    }
    
    .main-nav .navbar-brand h4{
        color: $light;
    }
    
    .section-title {
        font-size: 22px;
    }
    
    .feature-block{
        margin-bottom: 80px;
    }
    
    .service-img{
        margin-bottom: 0px;
    }
    
    .service-content {
        padding-bottom: 70px;
    }
    
    .process-block, .pricing-block{
        margin-bottom: 40px;
    }

    .section-heading{
        margin-bottom: 30px;
    }
    .project-content-block {
        text-align: center;
        margin-left: 0px;
        padding: 70px 20px;
        position: relative;
        bottom: 0px;
        margin-top: 40px;
    }
    .project-content-block h4 {
        font-size: 14px;
    }
   
    .blog-box {
        margin-bottom: 50px;
    }
    .footer-copy{
        text-align: center;
    }
    .footer-copy:before{
        display: none;
    }
   
    .banner-content p{
        padding-right: 0px;
    }
    
    .pricing-box{
        margin-top: 30px;
    }
    
    .main-navigation.menu-2 {
        margin-top: 20px;
        position: absolute;
        width: 100%;
    }
    
    .top-info-block{
        margin: 0px 20px;
        text-align: left;
    }
    
    .top-info-block .icon-block {
        margin-right: 10px;
    }
    .img-icon-block:before {
        right: auto;
    }
    #map{
        margin-top: 40px;
    }

    .contact-info-block{
        margin-bottom: 25px;
    }
    #work-wrap p.lead{
        margin-bottom: 40px;
    }
}



/*----------------------------------
    WIDE MOBILE LAYOUT: 7680px
-----------------------------------*/

@include tablet{
    
    .display-4 {
        font-size: 2.5rem;
    }
    
    .top-info-block{
        margin: 10px 15px;
        clear: both;
    }
    
    .icon-block{
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .main-navigation{
        margin-top: 20px;
    }
    
    .main-nav .navbar-brand h4{
        color: $light;
    }
    
    .section-title {
        font-size: 22px;
    }
    
    .feature-block{
        margin-bottom: 80px;
    }
    
    .service-img{
        margin-bottom: 0px;
    }
    
    .service-content {
        padding-bottom: 70px;
    }
    
    .process-block, .pricing-block{
        margin-bottom: 40px;
    }
    
    .pricing-block.zoom-in{
        -webkit-transform: none;
        -ms-transform:none ;
        -o-transform: none;
        transform:none ;
    }
    .section-heading{
        margin-bottom: 30px;
    }
    .project-content-block {
        text-align: center;
        margin-left: 0px;
        padding: 70px 20px;
        position: relative;
        bottom: 0px;
        margin-top: 40px;
    }
    .project-content-block h4 {
        font-size: 14px;
    }
    
    .testimonial-heading{
        margin-bottom: 50px;
    }
    .blog-box {
        margin-bottom: 50px;
    }
    .footer-copy{
        text-align: center;
    }
    .footer-copy:before{
        display: none;
    }
   
    .banner-content p{
        padding-right: 0px;
    }
    
    .pricing-box{
        margin-top: 30px;
    }
    
    
    .main-navigation.menu-2 {
        margin-top: 20px;
        position: absolute;
        width: 100%;
    }

    .img-icon-block:before {
        right: auto;
    }
    .page-banner-area{
        padding: 160px 0px 110px;
    }

    .info-block-2 {
        margin-right: 35px;
        margin-bottom: 40px;
    }
    .navbar-collapse.collapse.show{
        padding-bottom: 30px;
        background: #c72c41;
    }

    #map{
        margin-top: 40px;
    }

    .contact-info-block{
        margin-bottom: 25px;
    }
    #work-wrap p.lead{
        margin-bottom: 40px;
    }
    
}

/* Extra Small Devices, Phones */

// @media (min-width: 478px) and (max-width: 640px) {
@include tablet-xs{
     .main-navigation {
        margin-top: 40px; 
    }
    a.navbar-brand h4 {
        color:$light;
      }
    
    .section-title {
      top: 0; }
    .background-text {
      display: none; } 

    .display-4 {
        font-size: 2.5rem;
    }
    .navbar-collapse.collapse.show{
        padding-bottom: 30px;
        background: #c72c41;
    }
    .top-info-block{
        text-align: center;
        margin: 10px 0px;
        clear: both;
    }
    
    .icon-block{
        margin-right: 0px;
        margin-bottom: 10px;
    }
    
    .main-navigation{
        margin-top: 20px;
    }
    
    .main-nav .navbar-brand h4{
        color: $light;
    }
    
    .section-title {
        font-size: 22px;
    }
    
    .feature-block{
        margin-bottom: 80px;
    }
    
    .service-img{
        margin-bottom: 0px;
    }
    
    .service-content {
        padding-bottom: 70px;
    }
    
    .process-block, .pricing-block{
        margin-bottom: 40px;
    }
    
    .pricing-block.zoom-in{
        -webkit-transform: none;
        -ms-transform:none ;
        -o-transform: none;
        transform:none ;
    }
    .section-heading{
        margin-bottom: 30px;
    }
    .project-content-block {
        text-align: center;
        margin-left: 0px;
        padding: 70px 20px;
        position: relative;
        bottom: 0px;
    }
    .project-content-block h4 {
        font-size: 14px;
    }
    
    .testimonial-heading{
        margin-bottom: 50px;
    }
    .blog-box {
        margin-bottom: 50px;
    }
    .footer-copy{
        text-align: center;
    }
    .footer-copy:before{
        display: none;
    }
    .project-content {
        text-align: center;
        padding: 50px 0px;
        position: relative;
    }
    
    .about-block{
        margin-top: 30px;
    }
    
    .banner-content p{
        padding-right: 0px;
    }
    .pricing-box{
        margin-top: 30px;
    }
    
    .main-navigation.menu-2 {
        margin-top: 20px;
        position: absolute;
        width: 100%;
    }
    
    
    .banner-content p{
        padding-right: 0px;
    }
    
    .pricing-box{
        margin-top: 30px;
    }
    
    
    .main-navigation.menu-2 {
        margin-top: 20px;
        position: absolute;
        width: 100%;
    }
    
    .banner-contact-form{
        padding: 45px 15px;
    }
    
    .web-service-block{
        margin-bottom: 30px;
    }
    

    .img-icon-block:before {
        right: auto;
    }
    #feature{
        padding-bottom: 0px;
    }
    .page-banner-area{
        padding: 160px 0px 110px;
    }
    #pricing-2{
        padding-left: 20px;
        padding-right: 20px;
    }
    .info-block-2 {
        margin-right: 35px;
        margin-bottom: 40px;
    }
    #map{
        margin-top: 40px;
    }

    .contact-info-block{
        margin-bottom: 25px;
    }
    #work-wrap p.lead{
        margin-bottom: 40px;
    }
    .navbar-toggler:focus {
        outline: none;
    }
}
   

/* Custom, iPhone Retina */ 
// @media (min-width : 320px) and (max-width: 478px)  {
@include mobile{
    .bg-light{
        background: transparent!important;
    }
    .main-navigation {
        margin-top: 40px; 
    }
    a.navbar-brand h4 {
        color:$light;
      }
    
    .top-info-block{
        text-align: center;
        margin: 10px 0px;
        clear: both;
    }
    
    .icon-block{
        margin-right: 0px;
        margin-bottom: 10px;
    }
    
    .main-navigation{
        margin-top: 20px;
    }
    
    .main-nav .navbar-brand h4{
        color: $light;
        margin-top: 10px;
    }
    
    .section-title {
        font-size: 22px;
    }
    .banner-contact-form{
        padding: 45px 15px!important;
    }
    .feature-block{
        margin-bottom: 80px;
    }
    
    .service-img{
        margin-bottom: 0px;
    }
    
    .service-content {
        padding-bottom: 70px;
    }
    
    .process-block, .pricing-block{
        margin-bottom: 40px;
    }
    
    .pricing-block.zoom-in{
        -webkit-transform: none;
        -ms-transform:none ;
        -o-transform: none;
        transform:none ;
    }
    .section-heading{
        margin-bottom: 30px;
    }
    .project-content-block {
        text-align: center;
        margin-left: 0px;
        padding: 70px 20px;
        position: relative;
        bottom: 0px;
        margin-top: 30px;
    }
    
    .project-content-block h4 {
        font-size: 14px;
    }
    
    .testimonial-heading{
        margin-bottom: 50px;
    }
    .blog-box {
        margin-bottom: 50px;
    }
    .footer-copy{
        text-align: center;
    }
    .footer-copy:before{
        display: none;
    }
    
    
    .about-block{
        margin-top: 30px;
    }


    .banner-content .display-4{
        font-size: 28px;
        line-height: 38px;
    }
    .banner-content p{
        padding-right: 0px;
    }
    
    .pricing-box{
        margin-top: 30px;
    }
    
    
    .main-navigation.menu-2 {
        margin-top: 20px;
        position: absolute;
        width: 100%;
    }
    
    .navbar-collapse.collapse.show{
        padding-bottom: 30px;
        background: #c72c41;
    }
    
    .web-service-block{
        margin-bottom: 30px;
    }
    
    
    .section{
        padding: 60px 0px;
    }
    
    .section-bottom{
        padding-bottom: 60px;    
    }
    .section-top{
        padding-top: 60px;    
    }
    
  
    
    .main-banner h1.display-4 {
        line-height: 42px;
        font-size: 30px;
    }
    
    #projects {
        margin-top: 60px;
    }
    
    .img-icon-block:before {
        right: auto;
    }

    #feature{
        padding-bottom: 0px;
    }

    .page-banner-area{
        padding: 160px 0px 110px;
    }

    .display-4{
        font-size: 30px;
    }

    .info-block-2 {
        margin-right: 35px;
        margin-bottom: 40px;
    }
    #map{
        margin-top: 40px;
    }

    .contact-info-block{
        margin-bottom: 25px;
    }
   #work-wrap p.lead{
       margin-bottom: 40px;
   }

 .navbar-toggler:focus {
        outline: none;
    }
}